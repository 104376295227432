import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useLocation, withRouter } from 'react-router'
import { IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react'
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonSegment, IonSegmentButton } from '@ionic/react'
import { apps, globe, help, home, cubeOutline, person, logoPwa, paperPlane, cart, people } from 'ionicons/icons'
import { informationCircleOutline, key, logIn, logOut, moonOutline, receipt } from 'ionicons/icons'
import { t } from 'i18next'
import { connect } from '../data/connect'
import { setApp, setDarkMode, setLang } from '../data/user/user.actions'
import { User } from '../models/User'
import WebPushToggle from '../pages/account/WebPushToggle'
import { Auth } from '../services/AuthService'
import './Menu.css'
import { isAdmin } from './util/Auth'
import { error } from './util/Log'

const routes = {
  homePages: [
    { title: 'Apps', path: '/apps', icon: apps },
    { title: 'Products', path: '/products', icon: cubeOutline },
    { title: 'About', path: '/about', icon: informationCircleOutline },
  ],
  appPages: [
    { title: 'Dashboard', path: '/apps', icon: home },
    { title: 'Credentials', path: '/apps/credentials', icon: key },
    { title: 'Web push', path: '/apps/web-push', icon: logoPwa },
    { title: 'Billing', path: '/apps/billing', icon: receipt },
  ],
  adminPages: [
    { title: 'Web Push subs', path: '/admin/web-push', icon: paperPlane },
    { title: 'Users', path: '/admin/users', icon: people },
  ],
  loggedInPages: [
    { title: 'Account', path: '/account', icon: person },
    { title: 'Plans', path: '/plans', icon: cart },
    { title: 'Support', path: '/support', icon: help },
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    { title: 'Plans', path: '/plans', icon: cart },
    { title: 'Support', path: '/support', icon: help },
  ],
}

interface Pages {
  title: string
  path: string
  icon: string
  routerDirection?: string
}
interface OwnProps extends RouteComponentProps {}
interface StateProps {
  lang: string
  darkMode: boolean
  menuEnabled: boolean
  app?: string
  user?: User
  isAuthenticated: boolean
}
interface DispatchProps {
  setDarkMode: typeof setDarkMode
  setLang: typeof setLang
  setApp: typeof setApp
}
interface MenuProps extends OwnProps, StateProps, DispatchProps {}

const Menu: FC<MenuProps> = ({
  darkMode,
  history,
  setDarkMode,
  menuEnabled,
  lang,
  setLang,
  app,
  setApp,
  user,
  isAuthenticated,
}) => {
  const location = useLocation()
  const [show, setShow] = useState(true)
  const [signOutInprogress, setSignOutInprogress] = useState(false)

  const handleSignOut = async (e: any) => {
    setSignOutInprogress(true)
    e.preventDefault()
    try {
      await Auth.Instance.signOut().then().catch(error)
    } catch (err) {
      error('Logout error:', err)
    }
    setSignOutInprogress(false)
  }

  const renderListItems = (list: Pages[]) => {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide='false'>
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection='none'
            className={location.pathname.endsWith(p.path) ? 'selected' : undefined}>
            <IonIcon slot='start' icon={p.icon} />
            <IonLabel>{t<string>(p.title)}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ))
  }

  const unsetApp = async () => {
    await setApp(undefined)
    history.push('/')
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, 300)

    return () => clearTimeout(timeout)
  }, [show]) // eslint-disable-line

  return (
    <>
      {show && (
        <IonMenu type='overlay' disabled={!menuEnabled} contentId='main'>
          <IonContent forceOverscroll={false}>
            <IonList lines='none'>
              <IonMenuToggle key={'home'} auto-hide='false'>
                <IonListHeader className='pointer' onClick={unsetApp}>
                  {!!user?.given_name ? user?.given_name : t<string>('Taak')}
                </IonListHeader>
              </IonMenuToggle>
              {!app && renderListItems(routes.homePages)}
              {!!app && renderListItems(routes.appPages)}
            </IonList>
            <IonList lines='none'>
              <IonListHeader>{t<string>('Account')}</IonListHeader>
              {isAuthenticated ? renderListItems(routes.loggedInPages) : renderListItems(routes.loggedOutPages)}
              {isAuthenticated && isAdmin(user) && (
                <>
                  <IonListHeader>{t<string>('Admin')}</IonListHeader>
                  {renderListItems(routes.adminPages)}
                </>
              )}
              {isAuthenticated && (
                <IonMenuToggle key={'logout'} auto-hide='false'>
                  <IonItem
                    onClick={handleSignOut}
                    disabled={signOutInprogress}
                    detail={false}
                    routerDirection='none'
                    className={location.pathname.startsWith('/logout') ? 'selected pointer' : ' pointer'}>
                    <IonIcon slot='start' icon={logOut} />
                    <IonLabel>{t<string>('Logout')}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              )}
            </IonList>
            <IonList>
              <IonItem>
                <IonIcon slot='start' icon={globe}></IonIcon>
                <IonSegment value={lang} onIonChange={(e: any) => setLang(e.detail.value)} color='primary'>
                  {lang === 'fa' && (
                    <>
                      <IonSegmentButton value='fa'>
                        <IonLabel>فارسی</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value='en'>
                        <IonLabel>English</IonLabel>
                      </IonSegmentButton>
                    </>
                  )}
                  {lang !== 'fa' && (
                    <>
                      <IonSegmentButton value='en'>
                        <IonLabel>English</IonLabel>
                      </IonSegmentButton>
                      <IonSegmentButton value='fa'>
                        <IonLabel>فارسی</IonLabel>
                      </IonSegmentButton>
                    </>
                  )}
                </IonSegment>
              </IonItem>
              <IonItem>
                <IonIcon slot='start' icon={moonOutline}></IonIcon>
                <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)}>
                  {t<string>('Dark Mode')}
                </IonToggle>
              </IonItem>
              <WebPushToggle />
            </IonList>
          </IonContent>
        </IonMenu>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
    darkMode: state.user.darkMode,
    menuEnabled: state.data.menuEnabled,
    app: state.user.app,
    user: state.user.user,
    isAuthenticated: state.user.isAuthenticated,
  }),
  mapDispatchToProps: {
    setDarkMode,
    setLang,
    setApp,
  },
  component: React.memo(withRouter(Menu)),
})
