import React, { FC, useState } from 'react'
import { IonChip, IonCol, IonGrid, IonRow } from '@ionic/react'
import { t } from 'i18next'
import { connect } from '../../data/connect'
import AppContainer from '../AppContainer'
import WebPushToggle from '../account/WebPushToggle'
import WebPushList from './WebPushList'
import WebPushSubscribeButton from './WebPushSubscribeButton'

interface OwnProps {}
interface StateProps {
  webPush?: boolean
}
interface WebPushProp extends OwnProps, StateProps {}
const WebPush: FC<WebPushProp> = ({ webPush }) => {
  const [reloadWebPush, setReloadWebPush] = useState(false)

  return (
    <AppContainer id='web-push-page' title={t('Web push notifications')}>
      <IonGrid>
        {!('Notification' in window) && <IonChip color='danger'>Notifications aren't supported.</IonChip>}
        {!('PushManager' in window) && <IonChip color='danger'>Push messaging isn't supported.</IonChip>}
        {!webPush && (
          <>
            <IonRow>
              <IonCol>
                <IonChip color='warning'>Please enable web push first</IonChip>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <WebPushToggle />
              </IonCol>
            </IonRow>
          </>
        )}
        {webPush && (
          <>
            <IonRow>
              <IonCol>
                <WebPushSubscribeButton onSuccess={() => setReloadWebPush(!reloadWebPush)} />
              </IonCol>
            </IonRow>
          </>
        )}
      </IonGrid>
      <WebPushList reload={reloadWebPush} />
    </AppContainer>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    webPush: state.data.webPush,
  }),
  component: React.memo(WebPush),
})
